import Vue from 'vue'
import { sync } from 'vuex-router-sync'
import App from './App.vue'
import './plugins'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
// import VueAuth from '@websanova/vue-auth'
import * as Sentry from "@sentry/vue"
import axios from 'axios'

// Extract the subdomain from the hostname
Vue.subdomain = Vue.prototype.subdomain = window.location.hostname.split('.')[0]; // Gets 'subdomain'

// Map subdomains to API URLs
const apiConfig = {
  providers: process.env.VUE_APP_PROVIDERS_API,
  facilities: process.env.VUE_APP_FACILITIES_API,
  default: process.env.VUE_APP_ROOT_API,
}

// Determine which configuration to use
const config = apiConfig[Vue.subdomain] || apiConfig.default;

// Dynamically set axios defaults
axios.defaults.baseURL = config;

// Assign axios to Vue
Vue.axios = Vue.prototype.axios = Vue.prototype.$http = axios;

import driverAuthBearer from '@websanova/vue-auth/dist/drivers/auth/bearer.js'
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.js'
import driverOAuth2Google from '@websanova/vue-auth/dist/drivers/oauth2/google.js'
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.js'
import auth from '@websanova/vue-auth/dist/v2/vue-auth.common.js'
import './registerServiceWorker'

import { i18n } from './plugins/vue-i18n'

import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'reverb',
    key: process.env.VUE_APP_REVERB_APP_KEY,
    wsHost: process.env.VUE_APP_REVERB_HOST,
    wsPort: process.env.VUE_APP_REVERB_PORT,
    wssPort: process.env.VUE_APP_REVERB_PORT,
    forceTLS: (process.env.VUE_APP_REVERB_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
});

sync(store, router)

driverOAuth2Google.params.client_id = process.env.VUE_APP_GOOGLE_ID
driverOAuth2Google.params.response_type = 'token'
driverOAuth2Google.params.scope = 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'

Vue.use(auth, {
  plugins: {
    http: Vue.axios,
    router: Vue.router,
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
    router: driverRouterVueRouter,
    oauth2: {
      google: driverOAuth2Google,
    },
  },
  options: {
    tokenStore: ['vuex', 'storage', 'cookie'],
    logoutData: {
      makeRequest: true,
      redirect: '/login',
    },
  },
})

Vue.config.productionTip = false
Vue.config.silent = true

Vue.mixin({
  methods: {
    stringColor (str, opacity = 0.15, seed = 1) {
      var hash = 0
      if (!str || str.length === 0) return hash
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
        hash = hash & hash
      }
      var rgb = [0, 0, 0]
      for (i = 0; i < 3; i++) {
        var value = (hash >> (i * 8 * seed)) & 255
        rgb[i] = value
      }
      return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`
    },
    translateText (text, language, callback) {
      fetch('https://translation.googleapis.com/language/translate/v2?key=AIzaSyCq0WoDXCLtOCTI8iDg0EfexJO5IyBgxJc', {
        method: "POST",
        body: JSON.stringify({
          source: "en",
          target: language,
          format: "text",
          q: Array.isArray(text) ? text : [text],
        }),
      })
        .then(response => response.json())
        .then(data => {
          callback(Array.isArray(text) ? data.data.translations.map(t => t.translatedText) : data.data.translations[0].translatedText)
        })
    },
  },
})

Vue.prototype.$age_brackets = [
  { name: 'neonate', icon: 'fas fa-baby-carriage' },
  { name: 'infant', icon: 'fas fa-baby' },
  { name: 'child', icon: 'fas fa-child-reaching' },
  { name: 'adolescent', icon: 'fas fa-child' },
  { name: 'adult', icon: 'fas fa-person' },
  { name: 'geriatric', icon: 'fas fa-elderly' },
]

Vue.prototype.$sources = [
  { text: 'Blood', value: 'blood', icon: 'fal fa-tint' },
  { text: 'Bone', value: 'bone', icon: 'fal fa-bone' },
  { text: 'CNS', value: 'CNS', icon: 'fal fa-head-side-brain' },
  { text: 'Ear', value: 'ear', icon: 'fal fa-ear' },
  { text: 'Endometrial', value: 'endometrial', icon: 'fa-kit fa-uterus' },
  { text: 'Epidermal', value: 'epidermal', icon: 'fa-kit fa-skin' },
  { text: 'Eye', value: 'eye', icon: 'fal fa-eye' },
  { text: 'GI', value: 'gi', icon: 'fal fa-stomach' },
  { text: 'Joint', value: 'joint', icon: 'fa-kit fa-joint' },
  { text: 'Nail', value: 'nail', icon: 'fa-kit fa-fingernail' },
  { text: 'Oral', value: 'oral', icon: 'fal fa-lips' },
  { text: 'Rectal', value: 'rectal', icon: 'fa-kit fa-poop' },
  { text: 'Respiratory', value: 'respiratory', icon: 'fal fa-lungs' },
  { text: 'Semen', value: 'semen', icon: 'fa-kit fa-sperm' },
  { text: 'Sinuses', value: 'sinuses', icon: 'fa-kit fa-nose' },
  { text: 'Urogenital', value: 'urogenital', icon: 'fal fa-heart' },
  { text: 'Throat', value: 'throat', icon: 'fa-kit fa-throat' },
  { text: 'Urine', value: 'urine', icon: 'fal fa-prescription-bottle' },
  { text: 'Vaginal', value: 'vaginal', icon: 'fal fa-venus' },
  { text: 'Wound', value: 'wound', icon: 'fa-kit fa-bandage-angle' },
  { text: 'Unknown', value: 'unknown', icon: 'fal fa-question-circle' },
  { text: 'Invalid', value: 'invalid', icon: 'fal fa-diamond-exclamation' },
]

Vue.prototype.$flags = [
  { value: 'broad_spectrum', text: 'Broad-spectrum', color:'' },
  { value: 'risk_c_diff', text: 'C. diff Risk', color:'lime darken-4' },
  { value: 'second_line', text: 'Second Line', color:'orange darken-2' },
  { value: 'lab_monitoring', text: 'Lab Monitoring', color:'brown darken-2' },
  { value: 'caution', text: 'Caution', color:'error' },
  { value: 'fda_warnings', text: 'FDA Warning', color:'black' },
  { value: 'over_3_days', text: 'Duration', color:'pink darken-3' },
  { value: 'no_end_date', text: 'No End Date', color:'pink' },
]

Vue.prototype.$routes = [
  { value: 'PO', text: 'PO - Oral', color:'' },
  { value: 'IV', text: 'IV - Intravenous', color:'' },
  { value: 'IR', text: 'IR - Irrigation', color:'' },
  { value: 'IM', text: 'IM - Intramuscular', color:'' },
  { value: 'OP', text: 'OP - Ophthalmic', color:'' },
  { value: 'NG', text: 'NG - Nasogastric', color:'' },
  { value: 'As Ordered', text: 'As Ordered', color:'' },
  { value: 'XX', text: 'XX', color:'' },
  { value: 'TOP', text: 'TOP - Topical', color:'' },
  { value: 'PT', text: 'PT', color:'' },
  { value: 'PV', text: 'PV', color:'' },
  { value: 'INH', text: 'INH - Inhalation', color:'' },
  { value: 'OT', text: 'OT - Otic', color:'' },
  { value: 'OS', text: 'OS', color:'' },
  { value: 'LEFT EAR', text: 'LEFT EAR', color:'' },
]

Vue.prototype.$delivery_methods = [
  { value: 'po', text: 'Oral', icon: 'fa-kit fa-pills' },
  { value: 'top', text: 'Topical', icon: 'fa-kit fa-ointment' },
  { value: 'iv', text: 'Intravenous', icon: 'fa-kit fa-intravenous' },
  { value: 'im', text: 'Intramuscular', icon: 'fa-kit fa-intramuscular' },
  { value: 'pr', text: 'Per Rectum', icon: 'fa-kit fa-buttocks' },
  { value: 'pv', text: 'Per Vagina', icon: 'fa-kit fa-vaginal' },
  { value: 'inhale', text: 'Inhalation', icon: 'fa-kit fa-inhaler'},
  { value: 'ia', text: 'Irrigation', icon: 'fa-light fa-shower-down' },
  { value: 'ocular', text: 'Ocular', icon: 'fa-light fa-eye-dropper'},
  { value: 'io', text: 'Intraocular', icon: 'fa-light fa-eye'},
  { value: 'intraventricular', text: 'Intraventricular', icon: 'fa-light fa-head-side-brain'},
  { value: 'it', text: 'Intrathecal', icon: 'fa-kit fa-spine' },
  { value: 'nasal', text: 'Nasal', icon: 'fa-light fa-nose' },
  { value: 'nas', text: 'Intranasal', icon: 'fa-kit fa-nose-syringe' },
  { value: 'ip', text: 'Intraperitoneal', icon: 'fa-light fa-person' },
  { value: 'ives', text: 'Intravesicular', icon: 'fa-light fa-kidneys' },
  { value: 'intrapleural', text: 'Intrapleural', icon: 'fa-light fa-lungs' },
  { value: 'au', text: 'Otic', icon: 'fa-light fa-ear' },
]

Vue.prototype.$testing_methods = [
  { value: 'pcr', text: 'PCR', icon: 'fa-light fa-dna' },
  { value: 'ngs', text: 'NGS', icon: 'fa-light fa-dna' },
  { value: 'culture', text: 'Culture', icon: 'fa-light fa-bacteria' },
  { value: 'fusion', text: 'Fusion', icon: 'fa-light fa-atom-simple' },
]

Vue.prototype.$languages = [
  {
    value: 'en', text: 'English', menu: 'en',
  },{
    value: 'de', text: 'Deutsch', menu: 'de',
  },{
    value: 'es', text: 'Español', menu: 'es',
  },{
    value: 'fr', text: 'Français', menu: 'fr',
  },{
    value: 'it', text: 'Italiano', menu: 'it',
  },{
    value: 'pt', text: 'Português', menu: 'pt',
  },{
    value: 'iw', text: 'עברית', menu: 'ע', flag: 'il', rtl: true,
  },
]

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})
Vue.prototype.$Sentry = Sentry

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
